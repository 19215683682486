<template>
  <list-layout>
    <template slot="search">
      <b-col cols="4">
        <div class="position-relative">
          <b-form-input v-model="search" placeholder="Search" class="pr-12" @keyup.enter="searchName = search" />
          <font-awesome-icon
            :icon="['fas', 'search']"
            fixed-width
            class="input-search text-primary"
            @click="searchName = search"
          />
        </div>
      </b-col>
      <b-col cols="2">
        <b-form-select v-model="country" :options="countryList">
          <template #first>
            <b-form-select-option :value="null">
              Select Country
            </b-form-select-option>
          </template>
        </b-form-select>
      </b-col>
    </template>
    <template slot="actions">
      <b-button variant="secondary" class="btn-action text-primary" :to="{ name: 'PartnerCreate' }">
        <font-awesome-icon :icon="['fas', 'plus']" fixed-width />
      </b-button>
    </template>
    <template slot="list">
      <b-table
        hover
        borderless
        :items="sortPartnerList"
        :fields="fields"
        :current-page="currentPage"
        :per-page="rowPrePage"
        @row-clicked="onPartnerSelected"
      >
        <template #head(name)>
          <div class="cursor-pointer" @click="sortList('name')">
            NAME
            <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
          </div>
        </template>
        <template #head(status)>
          <div class="cursor-pointer" @click="sortList('status')">
            STATUS
            <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
          </div>
        </template>
        <template #head(location)>
          <div class="cursor-pointer" @click="sortList('location')">
            LOCATION
            <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
          </div>
        </template>
        <template #head(partner)>
          <div class="cursor-pointer" @click="sortList('partner')">
            PARTNERS
            <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
          </div>
        </template>
        <template #head(clinic)>
          <div class="cursor-pointer" @click="sortList('clinic')">
            CLINICS
            <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
          </div>
        </template>
        <template #head(type)>
          <div class="cursor-pointer" @click="sortList('type')">
            OPERATION TYPE
            <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
          </div>
        </template>
        <template #cell(name)="data">
          {{ data.item.name }}
        </template>
        <template #cell(status)="data">
          <span v-if="data.item.enabled === false" class="d-inline-block bg-danger text-white rounded-pill px-2 py-0">
            Deactivated
          </span>
          <span v-else-if="data.item.status === 0" class="d-inline-block bg-primary text-white rounded-pill px-2 py-0">
            Pending
          </span>
          <span v-else class="d-inline-block bg-success text-white rounded-pill px-2 py-0">
            Active
          </span>
        </template>
        <template #cell(type)="data">
          <span v-if="data.item.distributor" class="d-inline-block bg-info text-white rounded-pill px-2 py-0 mr-2">
            Distributor
          </span>
          <span v-if="data.item.service_provide" class="d-inline-block bg-info2 text-white rounded-pill px-2 py-0">
            Service Provider
          </span>
        </template>
      </b-table>
      <div class="d-flex justify-content-between mt-6">
        <div />
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="rowPrePage"
          align="fill"
          size="sm"
          class="my-0"
          first-number
          last-number
        />
        <row-count :total-rows="totalRows" :row-pre-page="rowPrePage" :current-page="currentPage" />
      </div>
    </template>
  </list-layout>
</template>

<script>
import listLayout from '@/components/layouts/listLayout.vue'
import countryList from '@/common/countryList'
import { getPartnerList } from '@/api/partner'
import RowCount from '@/components/common/rowCount'

export default {
  name: 'PartnerList',
  components: {
    listLayout,
    RowCount
  },
  data () {
    return {
      search: '',

      searchName: '',
      country: null,
      field: 'name',
      ascending: true,

      partnerList: [],
      totalRows: 0,
      currentPage: 1,
      rowPrePage: 6
    }
  },
  computed: {
    countryList () {
      return countryList
    },
    fields () {
      // return ['name', 'status', 'location', 'partner', 'clinic', 'type']
      return ['name', 'status', 'location', 'clinic', 'type']
    },
    filterPartnerList () {
      return this.partnerList.reduce((list, partner) => {
        if (this.searchName && partner.corporate_name.indexOf(this.searchName) === -1) {
          this.totalRows = list.length
          return list
        }
        if (this.country && partner.corporate_country !== this.country) {
          this.totalRows = list.length
          return list
        }

        let typeCount = 0
        if (partner.service_provide) typeCount += 1
        if (partner.distributor) typeCount += 1
        const country = this.countryList.find(c => c.value === partner.corporate_country)
        list.push({
          id: partner.id,
          enabled: partner.enabled,
          name: partner.corporate_name,
          status: partner.status,
          location: `${partner.corporate_city}, ${country?.text}`,
          partner: partner.partner_count,
          clinic: partner.clinic_count,
          type: typeCount,
          service_provide: partner.service_provide,
          distributor: partner.distributor
        })
        this.totalRows = list.length
        return list
      }, [])
    },
    sortPartnerList () {
      return this.filterPartnerList.slice().sort((a, b) => {
        const dataA = String(a[this.field]).toLowerCase()
        const dataB = String(b[this.field]).toLowerCase()
        if (dataA > dataB) return this.ascending ? 1 : -1
        if (dataA < dataB) return this.ascending ? -1 : 1
        else return 0
      })
    }
  },
  created () {
    this.getPartnerList()
  },
  methods: {
    getPartnerList () {
      getPartnerList().then(res => {
        this.partnerList = res.data.data
      })
    },
    sortList (field) {
      if (this.field === field) {
        this.ascending = !this.ascending
      } else {
        this.field = field
        this.ascending = true
      }
    },
    onPartnerSelected (item) {
      const id = item.id
      this.$store.commit('SET_BREADCRUMB', [
        {
          text: 'Partner List',
          to: { name: 'PartnerList' }
        },
        {
          text: item.name
        }
      ])
      this.$router.push({ name: 'PartnerDetail', params: { id } })
    }

  }
}
</script>
